<template>
  <power-bi code="eyJrIjoiZjczMWI4YTQtY2FjMy00YmM3LTgyMzMtODY4NzVhOGE4MjcwIiwidCI6ImM4NDJjMWQ5LWQzNjQtNDcxNi1iN2UzLWNhNDgxYTIzZDZhYyJ9" />
</template>

<script>
import PowerBi from '../reports/components/PowerBi.vue';

export default {
    components: {
        PowerBi,
    },
};
</script>
